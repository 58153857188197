import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './HomePage.css';
import { searchOffers, getActivities, addRequest, setBarterNotification } from '../api';
import Navbar from '../components/navbar';
import "../components/list.css"
import { Modal, Button, Form } from 'react-bootstrap';
import MultiSelectDropdown from '../components/MultiSelectDropdown';
import { Link, useNavigate } from 'react-router-dom';

const SearchPage = () => {
  const [searchTitles, setSearchTitles] = useState([]); // Array for multiple titles
  const [searchDesc, setsearchDesc] = useState('');
  const [offerings, setOfferings] = useState([]);
  const [titles, setTitles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user, logout, isAuthenticated } = useAuth0();
  const [selectedOffering, setSelectedOffering] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchTitles = async () => {
      try {
        const response = await getActivities();
        setTitles(response.data); // Assume response.data contains an array of titles
      } catch (error) {
        console.error('Error fetching titles:', error);
      }
    };

    fetchTitles();
  }, []); 

  // Handle search input changes
  const handleSearchChange = () => {
    console.log(searchTitles);
    console.log(searchDesc);
    searchOffers(searchTitles, searchDesc).then((response) => {
        setOfferings(response.data);
        setIsLoading(false);
    }).finally(() => {
        setIsLoading(false);
    });
  };

  // Trigger fetch when searchTitles or searchDesc changes
  useEffect(() => {
    setOfferings([]);
    searchOffers(searchTitles, searchDesc).then((response) => {
      setOfferings(response.data);
      setIsLoading(false);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [searchTitles, searchDesc]);

  const handleOfferingClick = (offering) => {
    setSelectedOffering(offering);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedOffering(null);
  };

  const handleAddRequest = () => {
    addRequest(user.email, selectedOffering.email, selectedOffering.id);
    setBarterNotification(selectedOffering.email, "false");
    handleCloseModal();
  };

  return (
    <>
      <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', alignItems: 'center' }}>
        {isAuthenticated && <p style={{ margin: 0, marginRight: '10px' }}>Welcome</p>}
        <button className='btn btn-secondary' onClick={() => logout({ returnTo: window.location.origin })}>Log out</button>
      </div>

      <div className="container-fluid">
        <div className="row">
          <Navbar />
          <div className="col-10">
            <div style={{ paddingTop: "80px" }} className="container mt-4">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <h1 style={{ textAlign: "center", paddingBottom: "30px" }}>Search</h1>
                  <div className="row g-0">
                    
                    {/* Multi-Select input */}
                    <div className="col-md-4">
                      <div className="input-group mb-3">
                      
                        <MultiSelectDropdown
                            options={titles.map((titleObj) => titleObj.title)}
                            selectedOptions={searchTitles}
                            setSelectedOptions={setSearchTitles}
                        />

                      </div>
                    </div>

                    {/* Text input */}
                    <div className="col-md-8">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Description..."
                          value={searchDesc}
                          onChange={(e) => setsearchDesc(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center">
                    <button className="btn btn-primary" onClick={handleSearchChange}>Search</button>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                {offerings.length > 0 ? (
                  offerings.map((offering) => (
                    <div key={offering.id} className="col-12 col-md-6">
                      <div className="card mb-3 shadow-sm hover-list" onClick={() => handleOfferingClick(offering)}>
                        <div className="card-body">
                          <h5 className="card-title">{offering.title}</h5>
                          <p className="card-text">{offering.description}</p>
                          <Link className="profile-bubble" to={`/profile/${offering.email}`}>
                            {offering.firstname + " " + offering.lastname}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-12">
                    {isLoading ? <p className="text-center">Loading...</p> : <p className="text-center">No offerings found.</p>}
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>

      {selectedOffering && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedOffering.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{selectedOffering.description}</p>
            <p>Offered by:</p>
            <Link to={`/profile/${selectedOffering.email}`}>
                          {selectedOffering.firstname + " " + selectedOffering.lastname}
                        </Link>

            {/* <a href={"/profile/" + selectedOffering.email}>{selectedOffering.firstname} {selectedOffering.lastname}</a>
            <br></br><br></br> */}
            <br></br>
            {selectedOffering.email !== user.email &&
            <>
             {/* <a href={"/messages/" +selectedOffering.email} style={{ textDecoration: "none" }}>
             <button style={{ width: "20%" }} className="btn btn-primary">Message</button>
            </a>  */}

            <Link to={`/messages/${selectedOffering.email}`} style={{ textDecoration: "none" }}>
              <button style={{ width: "fit-content" }} className="btn btn-primary">Message</button>
            </Link>
            </>
            }
           
          </Modal.Body>
          <Modal.Footer>
            {user && selectedOffering.email !== user.email && 
              <Button variant="primary" onClick={handleAddRequest}>Request</Button>}
            <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default SearchPage;



