import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom'; // Import Link
import { getBarterNotifications, getNotifications } from "../api";

const Navbar = () => {
    const { user, loginWithRedirect, isAuthenticated } = useAuth0();
    const [messageNotifs, setMessageNotifs] = useState([]);
    const [barterNotifs, setBarterNotifs] = useState([]);

    useEffect(() => {
        if (user) {
            getBarterNotifications(user.email).then((response) => {
                console.log(response.data);
                setBarterNotifs(response.data);
            }).catch((error) => {
                console.error('Error fetching barter notifications:', error);
            });

            getNotifications(user.email).then((response) => {
                console.log(response.data);
                setMessageNotifs(response.data);
            }).catch((error) => {
                console.error('Error fetching notifications:', error);
            });
        }
    }, [user]);

    return (
        <div className="col-1 bg-light vh-100 d-flex flex-column align-items-center p-3">
            <ul className="nav flex-column">
                <li className="nav-item mb-3">
                    <Link to="/profile" className="nav-link" style={{ color: "black" }}>
                        <i className="bi bi-person" style={{ fontSize: '1.5rem' }} aria-hidden="true"></i>
                    </Link>
                </li>
                <li className="nav-item mb-3">
                    <Link to="/search" className="nav-link" style={{ color: "black" }}>
                        <i className="bi bi-search" style={{ fontSize: '1.5rem' }} aria-hidden="true"></i>
                    </Link>
                </li>
                <li className="nav-item mb-3">
                    {messageNotifs.length === 0 ? (
                        <Link to="/conversations" className="nav-link" style={{ color: "black" }}>
                            <i className="bi bi-chat-dots" style={{ fontSize: '1.5rem' }} aria-hidden="true"></i>
                        </Link>
                    ) : (
                        <Link to="/conversations" className="nav-link" style={{ color: "black", position: "relative" }}>
                            <i className="bi bi-chat-dots" style={{ fontSize: '1.5rem' }} aria-hidden="true"></i>
                            <span 
                                style={{
                                    position: "absolute",
                                    top: "8px",
                                    right: "8px",
                                    width: "8px",
                                    height: "8px",
                                    backgroundColor: "red",
                                    borderRadius: "50%",
                                }}
                            ></span>
                        </Link>
                    )}
                </li>
                <li className="nav-item">
                    {barterNotifs.length > 0 && (
                        <Link to="/requests" className="nav-link" style={{ color: "black", position: "relative" }}>
                            <i className="bi bi-bell" style={{ fontSize: '1.5rem' }} aria-hidden="true"></i>
                            {barterNotifs[0].isRead === false && (
                                <span 
                                    style={{
                                        position: "absolute",
                                        top: "8px",
                                        right: "8px",
                                        width: "8px",
                                        height: "8px",
                                        backgroundColor: "red",
                                        borderRadius: "50%",
                                    }}
                                ></span>
                            )}
                        </Link>
                    )}
                </li>
            </ul>
        </div>
    )
}

export default Navbar;
