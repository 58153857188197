import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { getActivities } from "../api";

const AddSkill = ({ show, handleClose, skill, type, updateSkill }) => {
    const [currentSkill, setCurrentSkill] = useState({ title: skill.title || '', description: skill.description || '', type: type, id: skill.id || "" }); // Initialize with passed skill and type
    const [titles, setTitles] = useState([]);

    const handleSubmit = () => {
        updateSkill(currentSkill); // Call the updateSkill function with the currentSkill object
        handleClose(); // Close the modal after adding the skill
    };

    useEffect(() => {
        const fetchTitles = async () => {
            try {
                const response = await getActivities(); // Update with your API endpoint
                setTitles(response.data); // Assume response.data contains an array of titles
            } catch (error) {
                console.error('Error fetching titles:', error);
            }
        };

        fetchTitles();
    }, []); 

    // When the modal opens, reset the currentSkill
    useEffect(() => {
        setCurrentSkill({ title: skill.title || '', description: skill.description || '', type: type });
    }, [show, skill, type]); // Run this effect when show, skill, or type changes

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Skill</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formOfferTitle">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            as="select"
                            value={currentSkill.title}
                            onChange={(e) => setCurrentSkill({ ...currentSkill, title: e.target.value })} // Update the title in the currentSkill object
                        >
                            <option value="">Select a title</option>
                            {titles.map((titleObj, index) => (
                                <option key={index} value={titleObj.title}>{titleObj.title}</option> // Access the title from the object
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formSkillDescription" className="mt-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={currentSkill.description}
                            onChange={(e) => setCurrentSkill({ ...currentSkill, description: e.target.value })} // Update description
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Add Skill
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddSkill;
