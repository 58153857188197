import axios from 'axios';

const API_BASE_URL = 'https://cbbackend-11a2282b7acc.herokuapp.com';

export const getUsers = () => axios.get(`${API_BASE_URL}/users`);
export const addSeek = (seek) => axios.post(`${API_BASE_URL}/seeks`, seek);
export const getSeeks = (email) => axios.get(`${API_BASE_URL}/seeks/${email}`);
export const addOffer = (offer) => axios.post(`${API_BASE_URL}/offers`, offer);
export const getOffers = (email) => axios.get(`${API_BASE_URL}/offers/${email}`);
export const removeOffer = (title, description, email) => axios.delete(`${API_BASE_URL}/offers`, { data: { title, description, email } });
export const removeSeek = (title, description, email) => axios.delete(`${API_BASE_URL}/seeks`, { data: { title, description, email }});
export const editSeek = (title, description, id) => 
  axios.put(`${API_BASE_URL}/seeks`, { title, description, id });

export const editOffer = (title, description, id) => axios.put(`${API_BASE_URL}/offers`, {title, description, id});
export const uploadProfile = (url) => axios.post(`${API_BASE_URL}/profile_pic`, url);
export const getProfile = (email) => axios.get(`${API_BASE_URL}/user/${email}`);
// export const updateSeek = (seek) => axios.put(`${API_BASE_URL}/seeks`, seek);
export const searchOffers = (title, description) => 
    axios.get(`${API_BASE_URL}/search_offers`, {
      params: {
        title,
        description
      }
    });
export const getRequests = (requested_email) => axios.get(`${API_BASE_URL}/request`, {params: {requested_email}});
export const addRequest = (requestor_email, requested_email, offering_id) => axios.post(`${API_BASE_URL}/request`, {requestor_email, requested_email, offering_id});
export const updateOffer = (title, description, email) => axios.put(`${API_BASE_URL}/offers`, {data: {title, description, email}});
export const updateProfile = (username, firstname, lastname, email, zipcode, bio, profile_pic, links) => {
    return axios.put(`${API_BASE_URL}/user`, {
        username,
        firstname,
        lastname,
        email,
        zipcode,
        bio,
        profile_pic,
        links
    });
  };
export const getActivities = () => axios.get(`${API_BASE_URL}/activities`);
export const getBarters = (email) => axios.get(`${API_BASE_URL}/barter/${email}`);
export const createBarter = (requestor_email, requested_email, offeringa_id, offeringb_id, isCredit) => axios.post(`${API_BASE_URL}/barter`, {requestor_email, requested_email, offeringa_id, offeringb_id, isCredit});
export const getCredit = (requestor_id, requested_id) => axios.get(`${API_BASE_URL}/credit`, {params: {requestor_id, requested_id}});
export const deleteRequest = (requestor_email, requested_email, offering_id) => axios.delete(`${API_BASE_URL}/request`, {data: {requestor_email, requested_email, offering_id}});
export const deleteBarter = (barter_id) => axios.delete(`${API_BASE_URL}/barter`, {data: {barter_id}});
export const getLinks = (email) => axios.get(`${API_BASE_URL}/user_links/${email}`);
export const addLink = (email, link) => axios.post(`${API_BASE_URL}/user_links`, {email, link});
export const getMessages = (sender_email, receiver_email) => axios.get(`${API_BASE_URL}/messages`, {params: {sender_email, receiver_email}});
export const sendMessage = (sender_email, receiver_email, message) => axios.post(`${API_BASE_URL}/messages`, {sender_email, receiver_email, message});
// export const getConversations = (email) => axios.get(`${API_BASE_URL}/conversations`, {params: {email}});`);
export const getConversations = (email) => axios.get(`${API_BASE_URL}/conversations`, {params: {email}});
export const sendMessageNotification = (user_email, recipient_email) => axios.post(`${API_BASE_URL}/message_notifications`, {user_email, recipient_email});
export const getNotifications = (email) => axios.get(`${API_BASE_URL}/message_notifications`, {params: {email}});
export const clearNotification = (email, recipient_email) => axios.get(`${API_BASE_URL}/clear_message_notifications`, {params: {email, recipient_email}});
export const getBarterNotifications = (email) => axios.get(`${API_BASE_URL}/barter_notifications`, {params: {email}});
export const setBarterNotification = (email, isRead) => axios.post(`${API_BASE_URL}/barter_notifications`, {email, isRead});