import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom'; // Use HashRouter
import HomePage from './pages/HomePage';
import Profile from './pages/Profile';
import SearchPage from './pages/SearchPage';
import Requests from './pages/Requests';
import Messages from './pages/Messages';
import ConversationsPage from './pages/ConversationsPage';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router> {/* Ensure Router wraps Routes */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/:id" element={<Profile />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/requests" element={<Requests />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/messages/:id" element={<Messages />} />
        <Route path="/conversations" element={<ConversationsPage />} />
      </Routes>
    </Router>
  );
}

export default App;
