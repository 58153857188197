import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Navbar from '../components/navbar'; // Import your Navbar component
import Button from 'react-bootstrap/Button'; // Assuming you're using react-bootstrap
import { getOffers, getRequests, getBarters, setBarterNotification, createBarter, deleteBarter, getCredit, deleteRequest} from '../api'; // Define your API call

const Requests = () => {
  const [requests, setRequests] = useState([]);
  const [expandedRequestId, setExpandedRequestId] = useState(null);
  const [offerings, setOfferings] = useState({});
  const { user, logout, isAuthenticated } = useAuth0();
  const [barterOffer, setBarterOffer] = useState({});
  const [selectedOfferingId, setSelectedOfferingId] = useState(null);
  const [barters, setBarters] = useState([]);
  const [credit, setCredit] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(user) {
      // Fetch requests and offerings data from backend
      getRequests(user.email).then((response) => {
        setRequests(response.data);
        console.log(response.data);
        console.log("AAAAAAAAAAA")
      }).catch((error) => {
        console.error("Error fetching requests:", error);
      });

      getBarters(user.email).then((response) => {
        setBarters(response.data);
        console.log(response.data);
      }).catch((error) => {
        console.error("Error fetching barters:", error);
      }).finally(() => {
        setLoading(false);
      });

      setBarterNotification(user.email, "true");
    }
  }, [user]);



  const handleToggleOfferings = async (requestId, requestorEmail) => {
    if (expandedRequestId === requestId) {
      // If already expanded, collapse it
      setExpandedRequestId(null);
    } else {
      // Fetch offerings if expanding
      try {
        getOffers(requestorEmail)
        .then((response) => {
            setOfferings((prev) => ({ ...prev, [requestId]: response.data })); // Store fetched offerings by request ID
            setExpandedRequestId(requestId);
        })
        .catch((error) => {
          console.error('Error fetching offers:', error);
        });
         // Set the expanded request ID
      } catch (error) {
        console.error('Error fetching offerings:', error);
      }
    }
  };

  const handleCreateBarter = (request) => {
    // Add logic for creating a barter
    // requestor_email, requested_email, offeringa_id, offeringb_id, isCredit
    console.log(request)
    console.log(request)
    console.log(selectedOfferingId)
    createBarter(request.requestor_email, user.email, request.offer_id, selectedOfferingId, "false").then((response) => {
      console.log(response.data);
      setBarters([...barters, response.data]);
      deleteRequest(request.requestor_email, user.email, request.offer_id).then((response) => {
        console.log(response.data);
      }).catch((error) => {
        console.error('Error deleting request:', error);
      });
    }).catch((error) => {
      console.error('Error creating barter:', error);
    });

    setRequests(requests.filter((req) => req.request_id !== request.request_id));
    setBarterNotification(request.requestor_email, "false");
    setBarterNotification(user.email, "false");
  };

  const handleAcceptWithCredit = (request) => {
    // Add logic for accepting with credit
    createBarter(request.requestor_email, user.email, request.offer_id, -1, "true").then((response) => {
        console.log(response.data);
        console.log("WALBERTO!!!")
        setBarters([...barters, response.data]);
        deleteRequest(request.requestor_email, user.email, request.offer_id).then((response) => {
            console.log(response.data);
          }).catch((error) => {
            console.error('Error deleting request:', error);
          });
      }).catch((error) => {
        console.error('Error creating barter:', error);
      });
    setRequests(requests.filter((req) => req.request_id !== request.request_id));
    setBarterNotification(request.requestor_email, "false");
    setBarterNotification(user.email, "false");
  };

  const handleDeleteRequest = (request, requestId) => {
    // Add logic for deleting a request
    deleteRequest(request.requestor_email, user.email, request.offer_id).then((response) => {
        console.log(response.data);
        setRequests(requests.filter((request) => request.request_id !== request.request_id));
        }
    ).catch((error) => {
        console.error('Error deleting request:', error);
    }
    );
  };

  const handleAcceptBarter = (barterId) => {
    // Add logic for accepting a barter
  }

  const handleDeleteBarter = (barterId) => {
    // Add logic for deleting a barter
    console.log(barterId)
    console.log("WALBERTO")
    deleteBarter(barterId).then((response) => {
      console.log(response.data);
      setBarters(barters.filter((barter) => barter.barter_id !== barterId));
    }).catch((error) => {
      console.error('Error deleting barter:', error);
    });

  }

  const viewCredit = (requestedId, requestorId) => {
    getCredit(requestedId, requestorId).then((response) => {
      console.log(response.data);
      setCredit(response.data);
      alert(`Credit Amount: ${response.data}`, "Credits");
    }).catch((error) => {
      console.error('Error fetching credit:', error);
    });
  }

  return (
    <>
    <div style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        display: 'flex',  // Use flexbox to align items horizontally
        alignItems: 'center'  // Vertically align the items if needed
      }}>
        {isAuthenticated && (
          <p style={{ margin: 0, marginRight: '10px' }}>Welcome</p>  // Adjust spacing with margin
        )}
        <button className='btn btn-secondary' onClick={() => logout({ returnTo: window.location.origin })}>
          Log out
        </button>
          </div>
    {loading && <p style={{textAlign:"center"}}>Loading...</p>}
    {!loading &&
    <>
    
    <div className="container-fluid">
  <div className="row">
    {/* Navbar occupies 1 column on the left */}
    <Navbar />

    {/* Main content occupies remaining 11 columns on large screens and full width on small screens */}
    <div className="col-lg-11 col-11">
      <div className="row">
        {/* Requests Section */}
        <div className="col-lg-6 col-12">
          <div className="mt-5">
            <h2 style={{ textAlign: "center" }}>Requests</h2>
            <p style={{ fontSize: "small" }}>
              These are the requests you have received for your offerings. You can choose a service you'd like, or get a credit for future use.
            </p>
            <div className="row">
              {requests.map((request, index) => (
                <div key={index} className="col-12 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">{request.offer_title}</h5>
                      <p className="card-text">{request.offer_description}</p>
                      <p className="card-text">
                        <strong>Requested by: </strong>
                        <a href={`/profile/${request.requestor_email}`}>{request.requestor_email}</a>
                      </p>
                      <Button
                        className="btn btn-secondary m-1"
                        onClick={() => handleToggleOfferings(request.request_id, request.requestor_email)}
                      >
                        {expandedRequestId === request.request_id ? 'Hide Offerings' : 'View Offerings'}
                      </Button>
                      {expandedRequestId === request.request_id && offerings[request.request_id] && (
                        <div className="mt-3">
                          <h6>Offerings:</h6>
                          <p>
                            Select an offering to create a trade. You agree to provide {request.offer_title} in exchange for one of the services this user provides. You can also choose to accept with credit.
                          </p>
                          <ul className="list-group">
                            {offerings[request.request_id].map((offering) => (
                              <li
                                key={offering.id}
                                className={`list-group-item hover-list ${selectedOfferingId === offering.id ? 'bg-primary text-white' : ''}`}
                                onClick={() => {
                                  if (selectedOfferingId === offering.id) {
                                    setSelectedOfferingId(null); // Deselect if already selected
                                  } else {
                                    setBarterOffer(offering);
                                    setSelectedOfferingId(offering.id); // Set the selected offering ID
                                  }
                                }}
                              >
                                <strong>{offering.title}</strong>: {offering.description}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <div className="mt-3">
                        {selectedOfferingId && (
                          <Button className="btn btn-primary m-1" onClick={() => handleCreateBarter(request)}>Create Barter</Button>
                        )}
                        <Button className="btn btn-info m-1" onClick={() => handleAcceptWithCredit(request)}>Accept with Credit</Button>
                        <Button className="btn btn-warning m-1" onClick={() => handleDeleteRequest(request, request.request_id)}>Delete Request</Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Trades Section */}
        <div className="col-lg-6 col-12">
          <div className="mt-5">
            <h2 style={{ textAlign: "center" }}>Trades</h2>
            <p style={{ fontSize: "small" }}>These are the transactions where you and another have agreed on an exchange of services.</p>
            <div className="row">
              {barters.map((barter, index) => (
                <div key={index} className="col-12 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-header text-center" style={{ margin: "10px 0" }}>You offer</h5>
                      {!barter.offeringa_title ? (
                        <>
                          <h5 className="card-title">Credit</h5>
                          <p className="card-text" style={{ marginBottom: "20px" }}>
                            <Button className="btn btn-info m-1" onClick={() => viewCredit(barter.requestor_id, barter.requested_id)}>View Total</Button>
                          </p>
                        </>
                      ) : (
                        <>
                          <h5 className="card-title">{barter.offeringa_title}</h5>
                          <p className="card-text" style={{ marginBottom: "20px" }}>{barter.offeringa_description}</p>
                        </>
                      )}
                      <h5 className="card-header text-center" style={{ margin: "10px 0" }}>They offer</h5>
                      {!barter.offeringb_title ? (
                        <>
                          <h5 className="card-title">Credit</h5>
                          <p className="card-text" style={{ marginBottom: "20px" }}>
                            <Button className="btn btn-info m-1" onClick={() => viewCredit(barter.requested_id, barter.requestor_id)}>View Total</Button>
                          </p>
                        </>
                      ) : (
                        <>
                          <h5 className="card-title">{barter.offeringb_title}</h5>
                          <p className="card-text" style={{ marginBottom: "20px" }}>{barter.offeringb_description}</p>
                        </>
                      )}
                      <div className="card-footer">
                        <Button className="btn btn-primary m-1" onClick={() => handleDeleteBarter(barter.barter_id)}>Complete</Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    </>
    }
    </>
  );
};

export default Requests;
