import React, { useEffect, useState, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './HomePage.css';
import OfferingsList from '../components/OfferingsList';
import SeeksList from '../components/SeeksList';
import axios from 'axios';
import { getProfile, addOffer, addSeek, updateProfile, getLinks, addLink, editOffer, editSeek, getOffers, getSeeks } from "../api";
import EditProfileModal from '../components/EditProfileModal';
import AddSkill from '../components/AddSkill';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Navbar from '../components/navbar';
import { Nav } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';  // Import useParams hook

function Profile() {
  const { user, logout, isAuthenticated } = useAuth0();
  const [offers, setOffers] = useState([]);

  const [seeks, setSeeks] = useState([]);

  const [image, setImage] = useState(null);
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(''); // Holds the URL of the uploaded image
  const [preview, setPreview] = useState('');
  const [userfName, setUserfName] = useState('');
  const [userlName, setUserlName] = useState('');
  const [userBio, setUserBio] = useState('');
  const [userImage, setUserImage] = useState(''); // State for the user's image URL
  const [showModal, setShowModal] = useState(false);
  const [showSkillModal, setShowSkillModal] = useState(false);
  const [currentSkill, setCurrentSkill] = useState({ title: '', description: '', type: '' });
  const [currentType, setCurrentType] = useState("");
  const [userauthenticator, setUserauthenticator] = useState(null);
  const fileInputRef = useRef();
  const { id } = useParams();


  useEffect(() => {
    // Wait for user to load
    if (user) {
      if (id) {
        if (id === user.email) {
          setUserauthenticator(user); // Set authenticated user
          console.log('Authenticated user:', user);
        } else {
          setUserauthenticator({ email: id }); // Set user based on id
        }
      } else {
        setUserauthenticator(user); // Set authenticated user
      }
    }
  }, [id, user]);

  
  useEffect(() => {
    if (userauthenticator) {
      getOffers(userauthenticator.email)
        .then((response) => {
          console.log('Offers:', response.data);
          setOffers(response.data);
        })
        .catch((error) => {
          console.error('Error fetching offers:', error);
        });

      getSeeks(userauthenticator.email)
        .then((response) => {
          console.log('Seeks:', response.data);
          setSeeks(response.data);
        })
        .catch((error) => {
          console.error('Error fetching seeks:', error);
        });
        getLinks(userauthenticator.email).then((response) => {
          const linksArray = response.data.map((item) => item.link); // Extract link values
          setLinks(linksArray);
      })
        .catch((error) => {
          console.error('Error fetching links:', error
          );
        });
    };
  }, [userauthenticator]);


  useEffect(() => {
    if (userauthenticator) {
      getProfile(userauthenticator.email)
        .then((response) => {
          console.log('Profile:', response.data);
          setUserfName(response.data[2] === "" ? response.data[4] : response.data[2]);
          setUserlName(response.data[3] === "" ? "" : response.data[3]);
          setUserBio(response.data[6] === "" ? "Tell the world a bit about yourself and your mission" : response.data[6]);
          
          // Set the user image URL if available
          if (response.data[7]) { // Assuming index 7 contains the image URL
            setUserImage(response.data[7]);
            setUrl(response.data[7]); // Set the URL to display the image immediately
          }
        })
        .catch((error) => {
          console.error('Error fetching profile:', error);
        });
    }
  }, [userauthenticator]);

  useEffect(() => {
    if (image) {
      handleUpload();
    }
  }, [image]); // Run handleUpload when image changes


  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);


  const handleOpenSkillModal = (type) => {
    setCurrentType(type); // Set the type based on the button clicked
    setCurrentSkill({ title: '', description: '', type }); // Reset currentSkill with type
    setShowSkillModal(true); // Open the modal
  };
  
  const handleCloseSkillModal = () => setShowSkillModal(false);

  //Function for updating personal information, only active if the user is authenticated and
  //you are not viewing a different profile
  const saveProfile = (updatedProfile) => {
    setUserfName(updatedProfile.userfname);
    setUserlName(updatedProfile.userlname);
    setUserBio(updatedProfile.bio);
    setLinks(updatedProfile.links);
    let sendLinks = '';
    for (let i = 0; i < updatedProfile.links.length; i++) {
      sendLinks = sendLinks + updatedProfile.links[i] + '|';
    }
    addLink(user.email, sendLinks);
    updateProfile("", updatedProfile.userfname, updatedProfile.userlname, user.email, "", updatedProfile.bio, userImage, ""); // Include userImage here if you want to update it
  };


  //Function for updating the skills, only active if the user is authenticated and
  // you are not viewing a different profile
  const updateSkill = (updatedSkill) => {
    if (updatedSkill.title === "") return; // Prevent adding empty skills
    if (currentType === "offer") {
        
        addOffer({ title: updatedSkill.title, description: updatedSkill.description, email: user.email }).then((response) => {
          const seekId = response.data;
          const skillWithId = { ...updatedSkill, id: seekId };
          setOffers((prevOffers) => [...prevOffers, skillWithId]);
        });
        
  
    } else {
        
        addSeek({ title: updatedSkill.title, description: updatedSkill.description, email: user.email }).then((response) => {
          const seekId = response.data;
          const skillWithId = { ...updatedSkill, id: seekId };
          setSeeks((prevSeeks) => [...prevSeeks, skillWithId]);
        });
        
    }
};

  //Function for updating the image, only active if the user is authenticated and
  // you are not viewing a different profile
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    // Create a preview URL for the selected image
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
    }
  };

  //Function for uploading the image, only active if the user is authenticated and
  // you are not viewing a different profile
  const handleUpload = async () => {
    if (!image) return;

    setLoading(true);
    
    const formData = new FormData();
    formData.append('file', image);
    formData.append('upload_preset', 'rvdfcgt6'); // Replace with your upload preset

    try {
      const response = await axios.post(`https://api.cloudinary.com/v1_1/dqqzjdfqz/image/upload`, formData);
      setUrl(response.data.secure_url); // Get the URL of the uploaded image
      updateProfile("", userfName, userlName, user.email, "", userBio, response.data.secure_url, ""); // Use the new uploaded image URL
      console.log('Image uploaded successfully:', response.data.secure_url);
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setLoading(false);
      // Revoke the object URL after the image is uploaded to free up memory
      URL.revokeObjectURL(preview);
      setPreview(''); // Clear the preview after upload
    }
  };


  //Function for updating the skills, only active if the user is authenticated and
  // you are not viewing a different profile
  const updateSeek = (updatedSeek) => {
    setSeeks((prevSeeks) =>
      prevSeeks.map((seek) =>
        seek.id === updatedSeek.id ? updatedSeek : seek
      )
    );
    console.log(updatedSeek)
    editSeek(updatedSeek.title, updatedSeek.description, updatedSeek.id);
  };

  //Function for updating the offers, only active if the user is authenticated and
  // you are not viewing a different
  const updateOffer = (updatedOffer) => {
    setOffers((prevOffers) =>
      prevOffers.map((offer) =>
        offer.id === updatedOffer.id ? updatedOffer : offer
      )
    );
    console.log(updatedOffer)
    editOffer(updatedOffer.title, updatedOffer.description, updatedOffer.id);
  };


  //Function for deleting the offers, only active if the user is authenticated and
  // you are not viewing a different profile
  const deleteOffer = (deletedOffer) => {
    setOffers((prevOffers) =>
      prevOffers.filter((offer) => offer.title !== deletedOffer.title && offer.description !== deletedOffer.description)
    );
  };

  //Function for deleting the seeks, only active if the user is authenticated and
  // you are not viewing a different profile
  const deleteSeek = (deletedSeek) => {
    setSeeks((prevSeeks) =>
      prevSeeks.filter((seek) => seek.title !== deletedSeek.title && seek.description !== deletedSeek.description)
    );
  };
  
  const handleClick = () => {
    if (userauthenticator && userauthenticator.email == user.email) {
      fileInputRef.current.click();
    }
    // Trigger the file input when the placeholder is clicked
    
  };

  return (
    <>
      <div style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        display: 'flex',  // Use flexbox to align items horizontally
        alignItems: 'center'  // Vertically align the items if needed
      }}>
        {isAuthenticated && (
          <p style={{ margin: 0, marginRight: '10px' }}>Welcome</p>  // Adjust spacing with margin
        )}
        <button className='btn btn-secondary' onClick={() => logout({ returnTo: window.location.origin })}>
          Log out
        </button>
      </div>

      <div className="container-fluid">
        <div className="row">
          {/* Left-anchored navbar */}
          <Navbar></Navbar>

          <div className="col-10">
            <div className="container mt-5">
              {/* Your existing code */}
              <div className="row">
                <div className="col-12 mb-5">
                  {/* Hidden File Input */}
                  <input
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: 'none' }} // Hide the input
                  />
                  {/* Clickable Image Placeholder */}
                  
                  <div
                    onClick={handleClick}
                    style={{
                      width: '150px',
                      height: '150px',
                      border: '2px dashed #007bff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      backgroundColor: preview ? 'transparent' : '#f0f0f0'
                    }}
                    className="rounded-circle mb-3"
                  >
                    {url ? (
                      <img
                        src={url}
                        className="rounded-circle"
                        style={{ width: '150px', margin: "auto", height: '150px' }}
                        alt="Profile"
                      />
                    ) : (
                      <span style={{ textAlign: "center", color: '#007bff' }}>Click to upload an image</span>
                    )}
                  </div>
                  {loading && <p>Loading...</p>}
                  <h1>{userfName + " " + userlName}</h1>
                  {links.length > 0 && <h5>Links</h5>}
                  <ul>
                    {links.map((link, index) => (
                      // <li key={index}>
                      //   <a href={"//" + link} target
                      //     ="_blank" rel="noreferrer">{link}</a>
                      // </li
                      <li key={index}>
                        <Link to={link} target="_blank" rel="noreferrer">{link}</Link>
                      </li>
                    ))}
                  </ul>
                  <p className="text-muted">{userBio}</p>
                  {userauthenticator && userauthenticator.email != user.email && (
                  //   <a href={"/messages/" + userauthenticator.email} style={{ textDecoration: "none" }}>
                  //   <button style={{ width: "20%" }} className="btn btn-primary">Message</button>
                  // </a>  

                  <Link to={`/messages/${ userauthenticator.email}`} style={{ textDecoration: "none" }}>
                  <button style={{ width: "fit-content" }} className="btn btn-primary">Message</button>
                  </Link>
                
                  )}
                  {userauthenticator && userauthenticator.email == user.email && (
                      <button onClick={handleOpenModal} className="btn btn-primary">Edit Info</button>
                    )}
                  {isAuthenticated && (
                    <EditProfileModal
                      show={showModal}
                      handleClose={handleCloseModal}
                      initialFirstname={userfName}
                      initialLastname={userlName}
                      initialBio={userBio}
                      initialLinks = {links}
                      saveProfile={saveProfile}
                    />
                  )}
                </div>
                
                <div className="row">
                  {/* Offerings List */}
                  <div className="col-md-6">
                    <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      {userauthenticator && userauthenticator.email == user.email && (
                        <h3 style={{ marginRight: "10px" }}>My Offerings</h3>
                      )}
                        {userauthenticator && userauthenticator.email !== user.email && (
                        <h3 style={{ marginRight: "10px" }}>Their Offerings</h3>
                      )}
                     
                      {userauthenticator && userauthenticator.email == user.email && (
                      <button
                        onClick={() => handleOpenSkillModal("offer")}
                        style={{ padding: '2px 8px' }}
                        className="btn btn-secondary"
                      >
                        <i className="bi bi-plus"></i>
                      </button>
                      )}
                      <AddSkill
                        show={showSkillModal}
                        handleClose={handleCloseSkillModal}
                        skill={currentSkill}
                        type={currentType}
                        updateSkill={updateSkill}
                      />
                    </div>

                    {userauthenticator && userauthenticator.email == user.email && (
                      <p style={{ textAlign: "center" }}>What are you willing to help others with?</p>
                    )}
                    {userauthenticator && userauthenticator.email !== user.email && (
                      <p style={{ textAlign: "center" }}>They are willing to help others with this</p>
                    )}
                    
                    <OfferingsList offers={offers} updateOffer={updateOffer} deleteOffer={deleteOffer} userauthenticator={userauthenticator} />
                  </div>

                  {/* Seeks List */}
                  <div className="col-md-6">
                    <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      
                      {userauthenticator && userauthenticator.email == user.email && (
                        <h3 style={{ marginRight: "10px" }}>I am Seeking</h3>
                      )}
                      {userauthenticator && userauthenticator.email !== user.email && (
                        <h3 style={{ marginRight: "10px" }}>They are Seeking</h3>
                      )}
                      {userauthenticator && userauthenticator.email == user.email && (
                         <button
                         onClick={() => handleOpenSkillModal("seeks")}
                         style={{ padding: '2px 8px' }}
                         className="btn btn-secondary"
                       >
                         <i className="bi bi-plus"></i>
                       </button>
                      
                      )}
                     
                      <AddSkill
                        show={showSkillModal}
                        handleClose={handleCloseSkillModal}
                        skill={currentSkill}
                        type={currentType}
                        updateSkill={updateSkill}
                      />
                    </div>
                    {userauthenticator && userauthenticator.email == user.email && (
                      <p style={{ textAlign: "center" }}>List the skills you would like to offer</p>
                    )}
                    {userauthenticator && userauthenticator.email !== user.email && (
                      <p style={{ textAlign: "center" }}>They are seeking these skills</p>
                    )}
                    
                    <SeeksList seeks={seeks} updateSeek={updateSeek} deleteSeek={deleteSeek} userauthenticator={userauthenticator}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Profile;

