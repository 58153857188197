import React, { useState, useEffect } from "react";
import './MessagePage.css'; // Create a CSS file for styling
import { getMessages, sendMessage, getProfile, clearNotification, sendMessageNotification } from "../api";
import { useAuth0 } from "@auth0/auth0-react";
import Navbar from '../components/navbar';
import { useParams } from 'react-router-dom';  // Import useParams hook

// Main MessagePage component
const MessagePage = ({ userEmail, otherPersonEmail, otherPersonName }) => {
  const [messages, setMessages] = useState([]);
  const [messageContent, setMessageContent] = useState("");
  const { user, loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const { id } = useParams();
  const [receiverName, setReceiverName] = useState("");

  useEffect(() => {
    if (user) {
    getMessages(user.email, id).then((response) => {
        setMessages(response.data);
        console.log(response.data)
        }).catch((error) => {
            console.error('Error fetching messages:', error);
        });
    getProfile(id).then((response) => {
        setReceiverName(response.data[2] + " " + response.data[3]);
        
    }).catch((error) => {
        console.error('Error fetching profile:', error);

    });

    clearNotification(user.email, id).then(() => {
        console.log("Notification cleared");
    
    }).catch((error) => {
        console.error('Error clearing notification:', error);    
    });

    }
  }, [user]);

  const handleSendMessage = async () => {
    sendMessage(user.email, id, messageContent).then(() => {
        setMessageContent(""); // Clear the message input
        getMessages(user.email, id).then((response) => {
            setMessages(response.data); // Update the messages list
            console.log(response.data)
        }).catch((error) => {
            console.error('Error fetching messages:', error);
        });
    }).catch((error) => {
        console.error('Error sending message:', error);
    });

    sendMessageNotification(user.email, id).then(() => {
        console.log("Notification sent");
    }).catch((error) => {
        console.error('Error sending notification:', error
        );
    }
    );
  };

  return (
    <>
    <div style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        display: 'flex',  // Use flexbox to align items horizontally
        alignItems: 'center'  // Vertically align the items if needed
      }}>
        {isAuthenticated && (
          <p style={{ margin: 0, marginRight: '10px' }}>Welcome</p>  // Adjust spacing with margin
        )}
        <button className='btn btn-secondary' onClick={() => logout({ returnTo: window.location.origin })}>
          Log out
        </button>
      </div>
      
    <div className="container-fluid">
    <div className="row">
        <Navbar />
        <br></br><br></br><br></br><br></br>
    <div className="chat-container mt-5">
      <h2 className="chat-header">{receiverName}</h2>
      {user && (
        <>
        <MessageList messages={messages} userEmail={user.email} />
      <MessageInput
        messageContent={messageContent}
        setMessageContent={setMessageContent}
        onSendMessage={handleSendMessage}
      />
      </>
      )}
      
    </div>
    </div>
    </div>
    </>
  );
};

// Component for listing messages with text bubble styling
const MessageList = ({ messages, userEmail }) => (
  <div className="message-list">
    {messages.map((msg, index) => (
      <div
        key={index}
        className={`message-bubble ${msg.sender_email === userEmail ? "sent" : "received"}`}
      >
        <p className="message-content">{msg.message}</p>
        <span className="message-timestamp">{msg.timestamp}</span>
      </div>
    ))}
  </div>
);

// Component for composing and sending a message
const MessageInput = ({ messageContent, setMessageContent, onSendMessage }) => (
  <div className="message-input-container">
    <textarea
      placeholder="Type your message..."
      value={messageContent}
      onChange={(e) => setMessageContent(e.target.value)}
      rows="3"
    />
    <button onClick={onSendMessage} disabled={!messageContent}>
      Send
    </button>
  </div>
);

export default MessagePage;


// Mock API methods (replace with actual API calls)
// const sendMessage = async (senderEmail, receiverEmail, message) => {
//   console.log("Message sent:", { senderEmail, receiverEmail, message });
// };

// // const getMessages = async (email) => {
// // //   return [
// // //     { sender: "alice@example.com", content: "Hello!", timestamp: "10:30 AM" },
// // //     { sender: email, content: "Hey there!", timestamp: "10:32 AM" },
// // //     { sender: "alice@example.com", content: "How are you?", timestamp: "10:35 AM" },
// // //   ];
// // // };
    
// // };
