import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import 'bootstrap/dist/css/bootstrap.min.css';
import './HomePage.css';

function HomePage() {
    const { user, loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/profile'); // Use navigate for redirection
        }
    }, [isAuthenticated, navigate]);

    return (
        <>
            <div className="hp-container">
                <div className="row center">
                    <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Collective Oasis</h1>
                    <br></br>
                    <br></br>
                    <div>
                    <h4
                      style={{
                        textAlign: "center",
                        backgroundColor: "#f0f0f0",
                        padding: "10px 20px",
                        borderRadius: "50px",
                        display: "inline-block"
                      }}
                    >
                      Create. Trade. Thrive.
                    </h4>
                    </div>
                    <br></br>
                    <br></br>
                    <div style={{ textAlign: "center" }}>
                        {!isAuthenticated && !isLoading && (
                            <div style={{ textAlign: "center" }}>
                                <button className='btn btn-primary' onClick={loginWithRedirect}>Log in/Sign Up</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomePage;
