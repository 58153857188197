import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

ReactDOM.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={"https://www.collectiveoasis.com/#/profile"}
    audience='https://cbbackend-9be1e11d91b2.herokuapp.com/add_user'
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);
