import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { getActivities, removeOffer } from "../api";
import { useAuth0 } from "@auth0/auth0-react";


const OfferingList = ({ offers, updateOffer, deleteOffer, userauthenticator }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentOffer, setCurrentOffer] = useState({ title: '', description: '' });
  const [titles, setTitles] = useState([]);
  const { user, logout, isAuthenticated } = useAuth0();
  

  const handleOpenModal = (offer) => {
    if (userauthenticator.email == user.email)  {
    setCurrentOffer(offer); // Load current offer data into modal
    setShowModal(true); // Show modal
    }
  };

  const handleCloseModal = () => setShowModal(false); // Close modal

  const handleSaveChanges = () => {
    updateOffer(currentOffer); // Call parent function to save the changes
    handleCloseModal(); // Close modal after saving
  };

  const handleDeleteOffer = () => {
    // if (window.confirm(`Are you sure you want to delete "${currentOffer.title}"?`)) {
        removeOffer(currentOffer.title, currentOffer.description, user.email);
        // Remove the offer from the list
        console.log(currentOffer)
        // offers = offers.filter((offer) => offer.title !== currentOffer.title || offer.description !== currentOffer.description);
        
        deleteOffer(currentOffer)// console.log(user.email)
        handleCloseModal(); // Close the modal
    // }
  };

  useEffect(() => {
    const fetchTitles = async () => {
      try {
        const response = await getActivities(); // Update with your API endpoint
        console.log(response.data)
        setTitles(response.data); // Assume response.data contains an array of titles
        console.log(titles)
      } catch (error) {
        console.error('Error fetching titles:', error);
      }
    };

    fetchTitles();
  }, []); // Empty dependency array to run only on mount

  return (
    <div>
      <ul className="list-group">
        {offers.map((offer, index) => (
            <li key={index} className="list-group-item hover-list" onClick={() => handleOpenModal(offer)} style={{ position: 'relative' }}>
            <h5>{offer.title}</h5>
            <p>{offer.description}</p>
            </li>
        ))}
        </ul>


      {/* Edit Modal */}
       <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Offer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formOfferTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                as="select"
                value={currentOffer.title}
                onChange={(e) => setCurrentOffer({ ...currentOffer, title: e.target.value })}
              >
                <option value="">Select a title</option>
                {titles.map((titleObj, index) => (
                  <option key={index} value={titleObj.title}>{titleObj.title}</option> // Access the title from the object
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formOfferDescription" className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={currentOffer.description}
                onChange={(e) => setCurrentOffer({ ...currentOffer, description: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
          <Button variant="danger" onClick={handleDeleteOffer}>
            Delete Offer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OfferingList;

