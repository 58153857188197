import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConversations, getNotifications } from "../api";
import Navbar from '../components/navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ConversationsPage.css';
import "../components/list.css"
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const ConversationsPage = () => {
  const [conversations, setConversations] = useState([]);
  const { user, logout, isAuthenticated } = useAuth0();
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (user) {
      getConversations(user.email).then((response) => {
        setConversations(response.data);
        console.log(response.data)
      }).catch((error) => {
        console.error('Error fetching conversations:', error);
      });

      getNotifications(user.email).then((response) => {
        console.log(response.data)
        setNotifications(response.data);

      }
      
      ).catch((error) => {
        console.error('Error fetching notifications:', error);
      });

      

    }
  }, [user]);

  const findIsRead = (conversation) => {
    const notification = notifications.find((notification) => notification.recipient_email === conversation.other_user_name);
    if (notification === undefined) {
      return true;
    }
    console.log(notification.isRead)
    return notification.isRead;
  }


  const goToMessenger = (conversation) => {
    // window.location.href = `/messages/${conversation.other_user_name}`;
    navigate(`/messages/${conversation.other_user_name}`); // Use navigate for redirection
  }

  return (
    <>
    <div style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        display: 'flex',  // Use flexbox to align items horizontally
        alignItems: 'center'  // Vertically align the items if needed
      }}>
        {isAuthenticated && (
          <p style={{ margin: 0, marginRight: '10px' }}>Welcome</p>  // Adjust spacing with margin
        )}
        <button className='btn btn-secondary' onClick={() => logout({ returnTo: window.location.origin })}>
          Log out
        </button>
      </div>
     
      <div className="container-fluid">
  <div className="row">
    <div className="col-1 bg-light vh-100 d-flex flex-column align-items-center p-3">
      
        <Navbar />
   
    </div>

    
    <div className="col-lg-11 col-10"> {/* Remaining width for messages */}
      <br></br>
      <br></br>
      <br></br>
      {conversations.map((conversation, index) => (
        <div
          className="card mb-3"
          key={index}
          style={{ maxWidth: '800px' }}
          onClick={() => goToMessenger(conversation)}
        >
          <div
            className={`card-body d-flex align-items-center ${findIsRead(conversation) ? 'hover-list' : 'hover-list-unread'}`}
          >
            <img
              src={conversation.other_user_pic || "/default-profile.png"}
              alt={`${conversation.other_user_name}'s profile`}
              className="rounded-circle me-3"
              style={{ width: '50px', height: '50px' }}
            />
            <div className="flex-grow-1">
            <h5 
                className="card-title mb-1 text-truncate d-inline-block" 
                style={{ maxWidth: "200px", overflow: "hidden", whiteSpace: "nowrap" }}
              >
                {conversation.other_user_name}
              </h5>
              <p 
                className="card-title mb-1 text-truncate d-inline-block" 
                style={{ maxWidth: "200px", overflow: "hidden", whiteSpace: "nowrap" }}
              >
                {conversation.last_message}
              </p>
              <br></br>
              <small className="text-muted">{conversation.timestamp}</small>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</div>



    </>
  );
};

export default ConversationsPage;
