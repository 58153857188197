import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, ListGroup } from 'react-bootstrap';

const EditProfileModal = ({
  show,
  handleClose,
  initialFirstname,
  initialLastname,
  initialBio,
  initialLinks,
  saveProfile
}) => {
  // State variables for the modal inputs
  const [userfname, setUserfname] = useState(initialFirstname);
  const [userlname, setUserlname] = useState(initialLastname);
  const [bio, setBio] = useState(initialBio);
  const [link, setLink] = useState(''); // Temp state for new link input
  const [links, setLinks] = useState(initialLinks || []); // Links list state

  // Use effect to set initial values when the modal opens
  useEffect(() => {
    setUserfname(initialFirstname);
    setUserlname(initialLastname);
    setBio(initialBio);
    setLinks(initialLinks || []); // Initialize links
  }, [initialFirstname, initialLastname, initialBio, initialLinks, show]);

  const handleAddLink = () => {
    if (link && links.length < 5) { // Limit to 5 links
      setLinks([...links, link]);
      setLink(''); // Clear the input field
    } else if (links.length >= 5) {
      alert("You can't add more than 5 links.");
    }
  };

  const handleRemoveLink = (index) => {
    setLinks(links.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveProfile({ userfname, userlname, bio, links }); // Save profile data including links
    handleClose(); // Close the modal
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formUserfname">
            <Form.Control
              type="text"
              placeholder="First Name"
              value={userfname}
              onChange={(e) => setUserfname(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formUserlname">
            <Form.Control
              type="text"
              placeholder="Last Name"
              value={userlname}
              onChange={(e) => setUserlname(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formBio" className="mt-3">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Bio"
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              required
            />
          </Form.Group>

          {/* Links Section */}
          <Form.Group controlId="formLinks" className="mt-3">
            <Form.Label>Links (up to 5)</Form.Label>
            <Form.Control
              type="url"
              placeholder="Add a new link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
            <Button variant="secondary" onClick={handleAddLink} className="mt-2">
              Add Link
            </Button>

            <ListGroup className="mt-3">
              {links.map((link, index) => (
                <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
                  <span>{link}</span>
                  <Button variant="danger" size="sm" onClick={() => handleRemoveLink(index)}>
                    Remove
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-3">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditProfileModal;
